<template>
  <v-img
    :src="
      $vuetify.breakpoint.mdAndUp
        ? 'https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1718/v1656162876/Heros/home_hero_kh8jwg.webp'
        : 'https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1718/v1656162876/Heros/home_hero_kh8jwg.webp'
    "
    min-height="99vh"
    max-height="99vh"
    max-width="100vw"
    class="pb-6 mt-2"
    dark
  >
    <v-overlay absolute :opacity="0.1" color="black">
      <v-card
        dark
        color="rgba(49, 49, 49, 0.95)"
        light
        class="pa-12"
        elevation="12"
        :width="$vuetify.breakpoint.smAndDown ? 350 : 400"
      >
        <v-form
          @submit.prevent="valid ? submit : validate"
          v-model="valid"
          ref="form"
        >
          <v-text-field
            label="Email*"
            v-model="email"
            :rules="emailRules"
            name="email"
            required
            hide-details="auto"
            class="mb-6"
          />
          <v-text-field
            label="Password*"
            v-model="password"
            :rules="passwordRules"
            name="password"
            type="password"
            required
            hide-details="auto"
            class="my-6"
          />
          <v-btn @click="validate" type="submit" color="primary" width="100%">
            Login
          </v-btn>
        </v-form>
      </v-card>
    </v-overlay>
  </v-img>
</template>

<script>
export default {
  data() {
    return {
      passwordRules: [(v) => !!v || "password is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      email: "",
      password: "",
      valid: false,
    };
  },
  methods: {
    validate() {
      let result = this.$refs.form.validate();
      if (result) this.submit();
    },
    submit() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
